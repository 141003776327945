/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateNotificationCommand } from '../models/create-notification-command';
import { DeleteNotificationCommand } from '../models/delete-notification-command';
import { GetSelectedEventDatasQuery } from '../models/get-selected-event-datas-query';
import { GetSelectedUserDatasQuery } from '../models/get-selected-user-datas-query';
import { ListNotificationTemplateDdlQuery } from '../models/list-notification-template-ddl-query';
import { ListNotificationTemplateDdlResult } from '../models/list-notification-template-ddl-result';
import { NotificationData } from '../models/notification-data';
import { NotificationRecipientEventsData } from '../models/notification-recipient-events-data';
import { NotificationRecipientUsersData } from '../models/notification-recipient-users-data';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation notificationGet
   */
  static readonly NotificationGetPath = '/api/Notification';

  /**
   * Esemény adatainak lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGet$Plain$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<NotificationData>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationData>;
      })
    );
  }

  /**
   * Esemény adatainak lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGet$Plain(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<NotificationData> {

    return this.notificationGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<NotificationData>) => r.body as NotificationData)
    );
  }

  /**
   * Esemény adatainak lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGet$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<NotificationData>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationData>;
      })
    );
  }

  /**
   * Esemény adatainak lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGet(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<NotificationData> {

    return this.notificationGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<NotificationData>) => r.body as NotificationData)
    );
  }

  /**
   * Path part for operation notificationPost
   */
  static readonly NotificationPostPath = '/api/Notification/Post';

  /**
   * Értesítés létrehozása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationPost$Plain$Response(params?: {
    body?: CreateNotificationCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Értesítés létrehozása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationPost$Plain(params?: {
    body?: CreateNotificationCommand
  },
  context?: HttpContext

): Observable<number> {

    return this.notificationPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Értesítés létrehozása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationPost$Response(params?: {
    body?: CreateNotificationCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Értesítés létrehozása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationPost(params?: {
    body?: CreateNotificationCommand
  },
  context?: HttpContext

): Observable<number> {

    return this.notificationPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation notificationDelete
   */
  static readonly NotificationDeletePath = '/api/Notification/Delete';

  /**
   * Kijelölt események törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationDelete$Response(params?: {
    body?: DeleteNotificationCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Kijelölt események törlése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationDelete(params?: {
    body?: DeleteNotificationCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.notificationDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation notificationGetTemplates
   */
  static readonly NotificationGetTemplatesPath = '/api/Notification/GetTemplates';

  /**
   * Értesítés sablonok lekérdezése forrás alapján.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetTemplates$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetTemplates$Plain$Response(params?: {
    body?: ListNotificationTemplateDdlQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListNotificationTemplateDdlResult>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationGetTemplatesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListNotificationTemplateDdlResult>;
      })
    );
  }

  /**
   * Értesítés sablonok lekérdezése forrás alapján.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetTemplates$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetTemplates$Plain(params?: {
    body?: ListNotificationTemplateDdlQuery
  },
  context?: HttpContext

): Observable<ListNotificationTemplateDdlResult> {

    return this.notificationGetTemplates$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListNotificationTemplateDdlResult>) => r.body as ListNotificationTemplateDdlResult)
    );
  }

  /**
   * Értesítés sablonok lekérdezése forrás alapján.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetTemplates()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetTemplates$Response(params?: {
    body?: ListNotificationTemplateDdlQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListNotificationTemplateDdlResult>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationGetTemplatesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListNotificationTemplateDdlResult>;
      })
    );
  }

  /**
   * Értesítés sablonok lekérdezése forrás alapján.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetTemplates$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetTemplates(params?: {
    body?: ListNotificationTemplateDdlQuery
  },
  context?: HttpContext

): Observable<ListNotificationTemplateDdlResult> {

    return this.notificationGetTemplates$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListNotificationTemplateDdlResult>) => r.body as ListNotificationTemplateDdlResult)
    );
  }

  /**
   * Path part for operation notificationGetSelectedEventDatas
   */
  static readonly NotificationGetSelectedEventDatasPath = '/api/Notification/GetSelectedEventDatas';

  /**
   * Értesítés Kiválasztott esemény(ek) lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetSelectedEventDatas$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetSelectedEventDatas$Plain$Response(params?: {
    body?: GetSelectedEventDatasQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationRecipientEventsData>>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationGetSelectedEventDatasPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationRecipientEventsData>>;
      })
    );
  }

  /**
   * Értesítés Kiválasztott esemény(ek) lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetSelectedEventDatas$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetSelectedEventDatas$Plain(params?: {
    body?: GetSelectedEventDatasQuery
  },
  context?: HttpContext

): Observable<Array<NotificationRecipientEventsData>> {

    return this.notificationGetSelectedEventDatas$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationRecipientEventsData>>) => r.body as Array<NotificationRecipientEventsData>)
    );
  }

  /**
   * Értesítés Kiválasztott esemény(ek) lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetSelectedEventDatas()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetSelectedEventDatas$Response(params?: {
    body?: GetSelectedEventDatasQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationRecipientEventsData>>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationGetSelectedEventDatasPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationRecipientEventsData>>;
      })
    );
  }

  /**
   * Értesítés Kiválasztott esemény(ek) lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetSelectedEventDatas$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetSelectedEventDatas(params?: {
    body?: GetSelectedEventDatasQuery
  },
  context?: HttpContext

): Observable<Array<NotificationRecipientEventsData>> {

    return this.notificationGetSelectedEventDatas$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationRecipientEventsData>>) => r.body as Array<NotificationRecipientEventsData>)
    );
  }

  /**
   * Path part for operation notificationGetSelectedUserDatas
   */
  static readonly NotificationGetSelectedUserDatasPath = '/api/Notification/GetSelectedUserDatas';

  /**
   * Értesítés Kiválasztott személy(ek) lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetSelectedUserDatas$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetSelectedUserDatas$Plain$Response(params?: {
    body?: GetSelectedUserDatasQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationRecipientUsersData>>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationGetSelectedUserDatasPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationRecipientUsersData>>;
      })
    );
  }

  /**
   * Értesítés Kiválasztott személy(ek) lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetSelectedUserDatas$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetSelectedUserDatas$Plain(params?: {
    body?: GetSelectedUserDatasQuery
  },
  context?: HttpContext

): Observable<Array<NotificationRecipientUsersData>> {

    return this.notificationGetSelectedUserDatas$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationRecipientUsersData>>) => r.body as Array<NotificationRecipientUsersData>)
    );
  }

  /**
   * Értesítés Kiválasztott személy(ek) lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetSelectedUserDatas()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetSelectedUserDatas$Response(params?: {
    body?: GetSelectedUserDatasQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationRecipientUsersData>>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationGetSelectedUserDatasPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationRecipientUsersData>>;
      })
    );
  }

  /**
   * Értesítés Kiválasztott személy(ek) lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetSelectedUserDatas$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  notificationGetSelectedUserDatas(params?: {
    body?: GetSelectedUserDatasQuery
  },
  context?: HttpContext

): Observable<Array<NotificationRecipientUsersData>> {

    return this.notificationGetSelectedUserDatas$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationRecipientUsersData>>) => r.body as Array<NotificationRecipientUsersData>)
    );
  }

  /**
   * Path part for operation notificationProcessSmsStatus
   */
  static readonly NotificationProcessSmsStatusPath = '/api/Notification/ProcessSmsStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationProcessSmsStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationProcessSmsStatus$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationProcessSmsStatusPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationProcessSmsStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationProcessSmsStatus(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.notificationProcessSmsStatus$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
