/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddDescriptionEventUserCommand } from '../models/add-description-event-user-command';
import { ApplyEventCommand } from '../models/apply-event-command';
import { CheckEventLimitRestrictionsQuery } from '../models/check-event-limit-restrictions-query';
import { CheckOverlappingEventsQuery } from '../models/check-overlapping-events-query';
import { CreateOrUpdateEventCommand } from '../models/create-or-update-event-command';
import { CreateOrUpdateEventLimitsCommand } from '../models/create-or-update-event-limits-command';
import { CreateOrUpdateEventUserCommand } from '../models/create-or-update-event-user-command';
import { CreateOrUpdateGroupEventEmployerLimitsCommand } from '../models/create-or-update-group-event-employer-limits-command';
import { DeleteEventsCommand } from '../models/delete-events-command';
import { EventData } from '../models/event-data';
import { EventLimitsData } from '../models/event-limits-data';
import { EventStatisticsData } from '../models/event-statistics-data';
import { EventUserData } from '../models/event-user-data';
import { ExportEventUsersQuery } from '../models/export-event-users-query';
import { GroupData } from '../models/group-data';
import { GroupEventsCommand } from '../models/group-events-command';
import { ImportEventsCommand } from '../models/import-events-command';
import { ImportedLineResult } from '../models/imported-line-result';
import { ListEventEmployerLimitsQuery } from '../models/list-event-employer-limits-query';
import { ListEventEmployerLimitsResult } from '../models/list-event-employer-limits-result';
import { ListEventGroupEmployerLimitsResult } from '../models/list-event-group-employer-limits-result';
import { ListEventUsersQuery } from '../models/list-event-users-query';
import { ListEventUsersResult } from '../models/list-event-users-result';
import { ListEventsQuery } from '../models/list-events-query';
import { ListEventsResult } from '../models/list-events-result';
import { NotifyEventCommand } from '../models/notify-event-command';
import { PublishEventsCommand } from '../models/publish-events-command';
import { ResignEventCommand } from '../models/resign-event-command';
import { SetEventStateCommand } from '../models/set-event-state-command';
import { SetEventUserPresenceStateCommand } from '../models/set-event-user-presence-state-command';
import { SetEventUserRoleCommand } from '../models/set-event-user-role-command';

@Injectable({
  providedIn: 'root',
})
export class EventService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation eventList
   */
  static readonly EventListPath = '/api/Event/List';

  /**
   * Események listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventList$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventList$Plain$Response(params?: {
    body?: ListEventsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListEventsResult>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListEventsResult>;
      })
    );
  }

  /**
   * Események listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventList$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventList$Plain(params?: {
    body?: ListEventsQuery
  },
  context?: HttpContext

): Observable<ListEventsResult> {

    return this.eventList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListEventsResult>) => r.body as ListEventsResult)
    );
  }

  /**
   * Események listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventList$Response(params?: {
    body?: ListEventsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListEventsResult>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListEventsResult>;
      })
    );
  }

  /**
   * Események listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventList(params?: {
    body?: ListEventsQuery
  },
  context?: HttpContext

): Observable<ListEventsResult> {

    return this.eventList$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListEventsResult>) => r.body as ListEventsResult)
    );
  }

  /**
   * Path part for operation eventPost
   */
  static readonly EventPostPath = '/api/Event/Post';

  /**
   * Esemény létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventPost$Plain$Response(params?: {
    body?: CreateOrUpdateEventCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Esemény létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventPost$Plain(params?: {
    body?: CreateOrUpdateEventCommand
  },
  context?: HttpContext

): Observable<number> {

    return this.eventPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Esemény létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventPost$Response(params?: {
    body?: CreateOrUpdateEventCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Esemény létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventPost(params?: {
    body?: CreateOrUpdateEventCommand
  },
  context?: HttpContext

): Observable<number> {

    return this.eventPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation eventPostNotification
   */
  static readonly EventPostNotificationPath = '/api/Event/PostNotification';

  /**
   * Esemény lejelentése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventPostNotification()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventPostNotification$Response(params?: {
    body?: NotifyEventCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventPostNotificationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Esemény lejelentése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventPostNotification$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventPostNotification(params?: {
    body?: NotifyEventCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventPostNotification$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventGroupEvent
   */
  static readonly EventGroupEventPath = '/api/Event/GroupEvent';

  /**
   * Kijelölt események csportosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventGroupEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventGroupEvent$Response(params?: {
    body?: GroupEventsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventGroupEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Kijelölt események csportosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventGroupEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventGroupEvent(params?: {
    body?: GroupEventsCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventGroupEvent$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventListEventGroups
   */
  static readonly EventListEventGroupsPath = '/api/Event/ListEventGroups';

  /**
   * Eseménycsoportok lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventListEventGroups$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventListEventGroups$Plain$Response(params?: {
    EventId?: number;
    SearchTerm?: string;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<GroupData>>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventListEventGroupsPath, 'get');
    if (params) {
      rb.query('EventId', params.EventId, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GroupData>>;
      })
    );
  }

  /**
   * Eseménycsoportok lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventListEventGroups$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventListEventGroups$Plain(params?: {
    EventId?: number;
    SearchTerm?: string;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<GroupData>> {

    return this.eventListEventGroups$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<GroupData>>) => r.body as Array<GroupData>)
    );
  }

  /**
   * Eseménycsoportok lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventListEventGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventListEventGroups$Response(params?: {
    EventId?: number;
    SearchTerm?: string;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<GroupData>>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventListEventGroupsPath, 'get');
    if (params) {
      rb.query('EventId', params.EventId, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GroupData>>;
      })
    );
  }

  /**
   * Eseménycsoportok lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventListEventGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventListEventGroups(params?: {
    EventId?: number;
    SearchTerm?: string;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<GroupData>> {

    return this.eventListEventGroups$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<GroupData>>) => r.body as Array<GroupData>)
    );
  }

  /**
   * Path part for operation eventDelete
   */
  static readonly EventDeletePath = '/api/Event/Delete';

  /**
   * Kijelölt események törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventDelete$Response(params?: {
    body?: DeleteEventsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Kijelölt események törlése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventDelete(params?: {
    body?: DeleteEventsCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventGet
   */
  static readonly EventGetPath = '/api/Event';

  /**
   * Esemény adatainak lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGet$Plain$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventData>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventData>;
      })
    );
  }

  /**
   * Esemény adatainak lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGet$Plain(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<EventData> {

    return this.eventGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventData>) => r.body as EventData)
    );
  }

  /**
   * Esemény adatainak lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGet$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventData>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventData>;
      })
    );
  }

  /**
   * Esemény adatainak lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGet(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<EventData> {

    return this.eventGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventData>) => r.body as EventData)
    );
  }

  /**
   * Path part for operation eventListUsers
   */
  static readonly EventListUsersPath = '/api/Event/ListUsers';

  /**
   * Esemény-felhasználók listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventListUsers$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventListUsers$Plain$Response(params?: {
    body?: ListEventUsersQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListEventUsersResult>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventListUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListEventUsersResult>;
      })
    );
  }

  /**
   * Esemény-felhasználók listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventListUsers$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventListUsers$Plain(params?: {
    body?: ListEventUsersQuery
  },
  context?: HttpContext

): Observable<ListEventUsersResult> {

    return this.eventListUsers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListEventUsersResult>) => r.body as ListEventUsersResult)
    );
  }

  /**
   * Esemény-felhasználók listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventListUsers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventListUsers$Response(params?: {
    body?: ListEventUsersQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListEventUsersResult>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventListUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListEventUsersResult>;
      })
    );
  }

  /**
   * Esemény-felhasználók listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventListUsers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventListUsers(params?: {
    body?: ListEventUsersQuery
  },
  context?: HttpContext

): Observable<ListEventUsersResult> {

    return this.eventListUsers$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListEventUsersResult>) => r.body as ListEventUsersResult)
    );
  }

  /**
   * Path part for operation eventSetState
   */
  static readonly EventSetStatePath = '/api/Event/SetState';

  /**
   * Esemény állapot módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSetState()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventSetState$Response(params?: {
    body?: SetEventStateCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventSetStatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Esemény állapot módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSetState$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventSetState(params?: {
    body?: SetEventStateCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventSetState$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventGetEventStatistics
   */
  static readonly EventGetEventStatisticsPath = '/api/Event/GetEventStatistics';

  /**
   * Esemény statisztikai mutatók lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventGetEventStatistics$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventStatistics$Plain$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    ShowGroupStatistics?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventStatisticsData>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventGetEventStatisticsPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('ShowGroupStatistics', params.ShowGroupStatistics, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventStatisticsData>;
      })
    );
  }

  /**
   * Esemény statisztikai mutatók lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventGetEventStatistics$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventStatistics$Plain(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    ShowGroupStatistics?: boolean;
  },
  context?: HttpContext

): Observable<EventStatisticsData> {

    return this.eventGetEventStatistics$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventStatisticsData>) => r.body as EventStatisticsData)
    );
  }

  /**
   * Esemény statisztikai mutatók lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventGetEventStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventStatistics$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    ShowGroupStatistics?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventStatisticsData>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventGetEventStatisticsPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('ShowGroupStatistics', params.ShowGroupStatistics, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventStatisticsData>;
      })
    );
  }

  /**
   * Esemény statisztikai mutatók lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventGetEventStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventStatistics(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    ShowGroupStatistics?: boolean;
  },
  context?: HttpContext

): Observable<EventStatisticsData> {

    return this.eventGetEventStatistics$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventStatisticsData>) => r.body as EventStatisticsData)
    );
  }

  /**
   * Path part for operation eventSetUserRole
   */
  static readonly EventSetUserRolePath = '/api/Event/SetUserRole';

  /**
   * Esemény felhasználói szerep módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSetUserRole()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventSetUserRole$Response(params?: {
    body?: SetEventUserRoleCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventSetUserRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Esemény felhasználói szerep módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSetUserRole$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventSetUserRole(params?: {
    body?: SetEventUserRoleCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventSetUserRole$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventGetEventUser
   */
  static readonly EventGetEventUserPath = '/api/Event/GetEventUser';

  /**
   * Felhasználó eseményhez rendelésének lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventGetEventUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventUser$Plain$Response(params?: {
    EventId?: number;
    UserId?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventUserData>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventGetEventUserPath, 'get');
    if (params) {
      rb.query('EventId', params.EventId, {});
      rb.query('UserId', params.UserId, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventUserData>;
      })
    );
  }

  /**
   * Felhasználó eseményhez rendelésének lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventGetEventUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventUser$Plain(params?: {
    EventId?: number;
    UserId?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<EventUserData> {

    return this.eventGetEventUser$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventUserData>) => r.body as EventUserData)
    );
  }

  /**
   * Felhasználó eseményhez rendelésének lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventGetEventUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventUser$Response(params?: {
    EventId?: number;
    UserId?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventUserData>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventGetEventUserPath, 'get');
    if (params) {
      rb.query('EventId', params.EventId, {});
      rb.query('UserId', params.UserId, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventUserData>;
      })
    );
  }

  /**
   * Felhasználó eseményhez rendelésének lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventGetEventUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventUser(params?: {
    EventId?: number;
    UserId?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<EventUserData> {

    return this.eventGetEventUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventUserData>) => r.body as EventUserData)
    );
  }

  /**
   * Path part for operation eventSetEventUser
   */
  static readonly EventSetEventUserPath = '/api/Event/SetEventUser';

  /**
   * Felhasználók eseményhez rendeléseinek módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSetEventUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventSetEventUser$Response(params?: {
    body?: CreateOrUpdateEventUserCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventSetEventUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Felhasználók eseményhez rendeléseinek módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSetEventUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventSetEventUser(params?: {
    body?: CreateOrUpdateEventUserCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventSetEventUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventSetEventUserPresenceState
   */
  static readonly EventSetEventUserPresenceStatePath = '/api/Event/SetEventUserPresenceState';

  /**
   * Esemény felhasználói jelenlét módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSetEventUserPresenceState()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventSetEventUserPresenceState$Response(params?: {
    body?: SetEventUserPresenceStateCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventSetEventUserPresenceStatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Esemény felhasználói jelenlét módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSetEventUserPresenceState$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventSetEventUserPresenceState(params?: {
    body?: SetEventUserPresenceStateCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventSetEventUserPresenceState$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventAddDescriptionEventUser
   */
  static readonly EventAddDescriptionEventUserPath = '/api/Event/AddDescriptionEventUser';

  /**
   * Eseményhez rendelt felhasználó megjegyzésének módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventAddDescriptionEventUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventAddDescriptionEventUser$Response(params?: {
    body?: AddDescriptionEventUserCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventAddDescriptionEventUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Eseményhez rendelt felhasználó megjegyzésének módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventAddDescriptionEventUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventAddDescriptionEventUser(params?: {
    body?: AddDescriptionEventUserCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventAddDescriptionEventUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventListEventGroupEmployerLimits
   */
  static readonly EventListEventGroupEmployerLimitsPath = '/api/Event/ListEventGroupEmployerLimits';

  /**
   * Esemény csoporthoz tartozó események létszám adatainak lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventListEventGroupEmployerLimits$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventListEventGroupEmployerLimits$Plain$Response(params?: {
    GroupId?: number;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListEventGroupEmployerLimitsResult>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventListEventGroupEmployerLimitsPath, 'get');
    if (params) {
      rb.query('GroupId', params.GroupId, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListEventGroupEmployerLimitsResult>;
      })
    );
  }

  /**
   * Esemény csoporthoz tartozó események létszám adatainak lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventListEventGroupEmployerLimits$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventListEventGroupEmployerLimits$Plain(params?: {
    GroupId?: number;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<ListEventGroupEmployerLimitsResult> {

    return this.eventListEventGroupEmployerLimits$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListEventGroupEmployerLimitsResult>) => r.body as ListEventGroupEmployerLimitsResult)
    );
  }

  /**
   * Esemény csoporthoz tartozó események létszám adatainak lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventListEventGroupEmployerLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventListEventGroupEmployerLimits$Response(params?: {
    GroupId?: number;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListEventGroupEmployerLimitsResult>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventListEventGroupEmployerLimitsPath, 'get');
    if (params) {
      rb.query('GroupId', params.GroupId, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListEventGroupEmployerLimitsResult>;
      })
    );
  }

  /**
   * Esemény csoporthoz tartozó események létszám adatainak lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventListEventGroupEmployerLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventListEventGroupEmployerLimits(params?: {
    GroupId?: number;
    RequestUserId?: number;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<ListEventGroupEmployerLimitsResult> {

    return this.eventListEventGroupEmployerLimits$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListEventGroupEmployerLimitsResult>) => r.body as ListEventGroupEmployerLimitsResult)
    );
  }

  /**
   * Path part for operation eventCreateOrUpdateGroupEventEmployerLimits
   */
  static readonly EventCreateOrUpdateGroupEventEmployerLimitsPath = '/api/Event/CreateOrUpdateGroupEventEmployerLimits';

  /**
   * Csoporthoz tartozó Események és munkáltató összerendelések létrehozása/módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventCreateOrUpdateGroupEventEmployerLimits()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventCreateOrUpdateGroupEventEmployerLimits$Response(params?: {
    body?: CreateOrUpdateGroupEventEmployerLimitsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventCreateOrUpdateGroupEventEmployerLimitsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Csoporthoz tartozó Események és munkáltató összerendelések létrehozása/módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventCreateOrUpdateGroupEventEmployerLimits$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventCreateOrUpdateGroupEventEmployerLimits(params?: {
    body?: CreateOrUpdateGroupEventEmployerLimitsCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventCreateOrUpdateGroupEventEmployerLimits$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventGetEventLimits
   */
  static readonly EventGetEventLimitsPath = '/api/Event/GetEventLimits';

  /**
   * Esemény létszám adatainak lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventGetEventLimits$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventLimits$Plain$Response(params?: {
    EventId?: number;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventLimitsData>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventGetEventLimitsPath, 'get');
    if (params) {
      rb.query('EventId', params.EventId, {});
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventLimitsData>;
      })
    );
  }

  /**
   * Esemény létszám adatainak lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventGetEventLimits$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventLimits$Plain(params?: {
    EventId?: number;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<EventLimitsData> {

    return this.eventGetEventLimits$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventLimitsData>) => r.body as EventLimitsData)
    );
  }

  /**
   * Esemény létszám adatainak lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventGetEventLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventLimits$Response(params?: {
    EventId?: number;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EventLimitsData>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventGetEventLimitsPath, 'get');
    if (params) {
      rb.query('EventId', params.EventId, {});
      rb.query('RequestUserId', params.RequestUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventLimitsData>;
      })
    );
  }

  /**
   * Esemény létszám adatainak lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventGetEventLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventGetEventLimits(params?: {
    EventId?: number;
    RequestUserId?: number;
  },
  context?: HttpContext

): Observable<EventLimitsData> {

    return this.eventGetEventLimits$Response(params,context).pipe(
      map((r: StrictHttpResponse<EventLimitsData>) => r.body as EventLimitsData)
    );
  }

  /**
   * Path part for operation eventCreateOrUpdateEventLimits
   */
  static readonly EventCreateOrUpdateEventLimitsPath = '/api/Event/CreateOrUpdateEventLimits';

  /**
   * Esemény és munkáltató összerendelések létrehozása/módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventCreateOrUpdateEventLimits()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventCreateOrUpdateEventLimits$Response(params?: {
    body?: CreateOrUpdateEventLimitsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventCreateOrUpdateEventLimitsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Esemény és munkáltató összerendelések létrehozása/módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventCreateOrUpdateEventLimits$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventCreateOrUpdateEventLimits(params?: {
    body?: CreateOrUpdateEventLimitsCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventCreateOrUpdateEventLimits$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation checkEventLimitRestrictions
   */
  static readonly CheckEventLimitRestrictionsPath = '/api/Event/CheckEventLimitRestrictions';

  /**
   * Esemény létszám szabályok ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkEventLimitRestrictions$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkEventLimitRestrictions$Plain$Response(params?: {
    body?: CheckEventLimitRestrictionsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.CheckEventLimitRestrictionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Esemény létszám szabályok ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkEventLimitRestrictions$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkEventLimitRestrictions$Plain(params?: {
    body?: CheckEventLimitRestrictionsQuery
  },
  context?: HttpContext

): Observable<boolean> {

    return this.checkEventLimitRestrictions$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Esemény létszám szabályok ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkEventLimitRestrictions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkEventLimitRestrictions$Response(params?: {
    body?: CheckEventLimitRestrictionsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.CheckEventLimitRestrictionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Esemény létszám szabályok ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkEventLimitRestrictions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkEventLimitRestrictions(params?: {
    body?: CheckEventLimitRestrictionsQuery
  },
  context?: HttpContext

): Observable<boolean> {

    return this.checkEventLimitRestrictions$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation checkOverlappingEvents
   */
  static readonly CheckOverlappingEventsPath = '/api/Event/CheckOverlappingEvents';

  /**
   * Átfedésben lévő események vizsgálata.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkOverlappingEvents$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkOverlappingEvents$Plain$Response(params?: {
    body?: CheckOverlappingEventsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.CheckOverlappingEventsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Átfedésben lévő események vizsgálata.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkOverlappingEvents$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkOverlappingEvents$Plain(params?: {
    body?: CheckOverlappingEventsQuery
  },
  context?: HttpContext

): Observable<boolean> {

    return this.checkOverlappingEvents$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Átfedésben lévő események vizsgálata.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkOverlappingEvents()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkOverlappingEvents$Response(params?: {
    body?: CheckOverlappingEventsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.CheckOverlappingEventsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Átfedésben lévő események vizsgálata.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkOverlappingEvents$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkOverlappingEvents(params?: {
    body?: CheckOverlappingEventsQuery
  },
  context?: HttpContext

): Observable<boolean> {

    return this.checkOverlappingEvents$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation eventExport
   */
  static readonly EventExportPath = '/api/Event/Export';

  /**
   * Eseményhez rendelt felhasználók exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventExport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventExport$Plain$Response(params?: {
    body?: ExportEventUsersQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Eseményhez rendelt felhasználók exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventExport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventExport$Plain(params?: {
    body?: ExportEventUsersQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.eventExport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Eseményhez rendelt felhasználók exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventExport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventExport$Response(params?: {
    body?: ExportEventUsersQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Eseményhez rendelt felhasználók exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventExport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventExport(params?: {
    body?: ExportEventUsersQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.eventExport$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation eventCsv
   */
  static readonly EventCsvPath = '/api/Event/Csv';

  /**
   * Esemény exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventCsv$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventCsv$Plain$Response(params?: {
    SearchTerm?: string;
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventCsvPath, 'get');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Esemény exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventCsv$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventCsv$Plain(params?: {
    SearchTerm?: string;
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.eventCsv$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Esemény exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventCsv$Response(params?: {
    SearchTerm?: string;
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventCsvPath, 'get');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Esemény exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventCsv(params?: {
    SearchTerm?: string;
    DateFrom?: string;
    DateTo?: string;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.eventCsv$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation eventImport
   */
  static readonly EventImportPath = '/api/Event/Import';

  /**
   * Események importálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventImport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventImport$Plain$Response(params?: {
    body?: ImportEventsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ImportedLineResult>>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventImportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImportedLineResult>>;
      })
    );
  }

  /**
   * Események importálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventImport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventImport$Plain(params?: {
    body?: ImportEventsCommand
  },
  context?: HttpContext

): Observable<Array<ImportedLineResult>> {

    return this.eventImport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ImportedLineResult>>) => r.body as Array<ImportedLineResult>)
    );
  }

  /**
   * Események importálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventImport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventImport$Response(params?: {
    body?: ImportEventsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ImportedLineResult>>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventImportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImportedLineResult>>;
      })
    );
  }

  /**
   * Események importálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventImport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventImport(params?: {
    body?: ImportEventsCommand
  },
  context?: HttpContext

): Observable<Array<ImportedLineResult>> {

    return this.eventImport$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ImportedLineResult>>) => r.body as Array<ImportedLineResult>)
    );
  }

  /**
   * Path part for operation eventListEventEmployerLimits
   */
  static readonly EventListEventEmployerLimitsPath = '/api/Event/ListEventEmployerLimits';

  /**
   * Esemény és munkáltató limitek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventListEventEmployerLimits$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventListEventEmployerLimits$Plain$Response(params?: {
    body?: ListEventEmployerLimitsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListEventEmployerLimitsResult>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventListEventEmployerLimitsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListEventEmployerLimitsResult>;
      })
    );
  }

  /**
   * Esemény és munkáltató limitek lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventListEventEmployerLimits$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventListEventEmployerLimits$Plain(params?: {
    body?: ListEventEmployerLimitsQuery
  },
  context?: HttpContext

): Observable<ListEventEmployerLimitsResult> {

    return this.eventListEventEmployerLimits$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListEventEmployerLimitsResult>) => r.body as ListEventEmployerLimitsResult)
    );
  }

  /**
   * Esemény és munkáltató limitek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventListEventEmployerLimits()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventListEventEmployerLimits$Response(params?: {
    body?: ListEventEmployerLimitsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListEventEmployerLimitsResult>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventListEventEmployerLimitsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListEventEmployerLimitsResult>;
      })
    );
  }

  /**
   * Esemény és munkáltató limitek lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventListEventEmployerLimits$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventListEventEmployerLimits(params?: {
    body?: ListEventEmployerLimitsQuery
  },
  context?: HttpContext

): Observable<ListEventEmployerLimitsResult> {

    return this.eventListEventEmployerLimits$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListEventEmployerLimitsResult>) => r.body as ListEventEmployerLimitsResult)
    );
  }

  /**
   * Path part for operation eventApplyEvent
   */
  static readonly EventApplyEventPath = '/api/Event/ApplyEvent';

  /**
   * Felhasználói esemény-jelentkezés.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventApplyEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventApplyEvent$Response(params?: {
    body?: ApplyEventCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventApplyEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Felhasználói esemény-jelentkezés.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventApplyEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventApplyEvent(params?: {
    body?: ApplyEventCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventApplyEvent$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventResignEvent
   */
  static readonly EventResignEventPath = '/api/Event/ResignEvent';

  /**
   * Felhasználói esemény lemondása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventResignEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventResignEvent$Response(params?: {
    body?: ResignEventCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventResignEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Felhasználói esemény lemondása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventResignEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventResignEvent(params?: {
    body?: ResignEventCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventResignEvent$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation eventPublishEvents
   */
  static readonly EventPublishEventsPath = '/api/Event/PublishEvents';

  /**
   * Esemény(ek) publikálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventPublishEvents()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventPublishEvents$Response(params?: {
    body?: PublishEventsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EventService.EventPublishEventsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Esemény(ek) publikálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventPublishEvents$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  eventPublishEvents(params?: {
    body?: PublishEventsCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.eventPublishEvents$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
